<template>
  <v-form ref="form" lazy-validation>
    <v-layout align-center class="mx-4 my-4">
      <v-flex shrink>
        <v-switch
          v-model="value.mode"
          :disabled="disabled"
          :class="[
            disabled && !enabled && 'zg-text-control-enabled-disabled',
            'zg-text-control mt-0 pt-0',
          ]"
          color="expressive_green"
          label="Mam pliki z danymi"
          value="file"
          hide-details
          :ripple="false"
          inset
        ></v-switch>
      </v-flex>
    </v-layout>
    <v-layout column v-if="enabled && !disabled">
      <DataSourceSelect class="mt-2" :value.sync="value.source" :disabled="disabed"/>
      <v-layout align-center class="mx-4 my-4">
        <DefaultLabel >
          Spakuj wszystkie pliki zawierające dane medyczne do archiwum ZIP i dodaj plik. Maksymalna wielkość pliku to 1 GB.
        </DefaultLabel>
      </v-layout>
      <v-flex class="mx-4 mb-4">
        <UploadButton
          column
          type="plik"
          accept=".zip"
          :value.sync="value.file"
          :disabled="disabled"
          :primary="false"
        ></UploadButton>
      </v-flex>
      <PasswordField
        label="Podaj hasło pliku"
        :icon="false"
        :rules="noRules"
        :value.sync="value.file_password"
        :disabled="disabled"
        :required="false"
      >
      </PasswordField>
      <v-flex grow class="mx-4" v-if="uploading">
        <v-layout column>
          <v-flex>
            <DefaultLabel>Wysyłanie {{ progress }}%</DefaultLabel>
          </v-flex>
          <v-progress-linear v-model="progress"></v-progress-linear>
        </v-layout>
      </v-flex>
      <DefaultButton
        v-if="uploading"
        class="mx-4"
        primary
        @click.prevent="cancel"
        >Anuluj</DefaultButton
      >
      <DefaultButton
        v-if="!disabled && !uploading"
        :loading="loading"
        class="mx-4"
        primary
        @click.prevent="proceed"
        >Potwierdź</DefaultButton
      >
    </v-layout>
  </v-form>
</template>

<script>
import axios from "axios";

import Validators from "@/mixins/Validators";
import DataImportService from "@/services/dataimport.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

const CancelToken = axios.CancelToken;
let stop = undefined;

export default {
  mixins: [ProxyCRUDMixin, Validators],
  props: {
    value: {},
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progress: 0,
      uploading: false,
      noRules: [() => true],
    };
  },
  computed: {
    enabled() {
      return this.value.mode === "file";
    },
    disabled() {
      return !!this.value.state;
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    UploadButton: () => import("@/components/buttons/p1/UploadButton"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
    PasswordField: () => import("@/components/auth/PasswordField"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DataSourceSelect: () => import("@/components/selects/DataSourceSelect")
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onProgress(progressEvent) {
      this.progress = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
    proceed() {
      if (!this.validate()) {
        return;
      }

      this.uploading = true;

      const formData = new FormData();

      if (this.value.source) {
        formData.append("source", this.value.source);
      }
      formData.append("file", this.value.file);
      formData.append("file_password", this.value.file_password ?? "");
      formData.append("status", this.value.status);

      this.beforeRequest();
      return DataImportService.updateDataImport(
        formData,
        this.onProgress,
        new CancelToken((c) => {
          stop = c;
        })
      )
        .then(() => {
          this.handleSuccess("Wysłano dane pomyślnie.");
          this.$emit("refresh");
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            this.handleError("Przerwano wysyłanie.");
          } else {
            this.handleError("Coś poszło nie tak.");
          }
        })
        .finally(() => {
          this.progress = 0;
          this.uploading = false;
        });
    },
    cancel() {
      if (stop) {
        stop();
        this.$emit("reset");
      }
    },
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}

.zg-text-control-enabled-disabled::v-deep label {
  opacity: 0.6 !important;
}
</style>
